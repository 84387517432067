import React from 'react'
import { GENDER_DATA } from '../../views/BrandProfilePage/constants'
import { BrandsCardType, OptionType } from '../../views/BrandProfilePage/type'
import { NeedleImage } from '../NeedleImage'
import { formatNumber, getIconUrl } from './component'
import ideasStore from '../../storeZustand/ideasStore'

interface DataDisplayCardProps {
    data: any
    type?: BrandsCardType
    currency: string
}

function DataDisplayCard({ data, type, currency }: DataDisplayCardProps) {
    switch (type) {
        case BrandsCardType.MultipleText:
            return (
                <div className="pt-4 px-6 pb-4 whitespace-pre-line">
                    {data.map((detailData: string, index: number) => (
                        <div
                            className="border font-body-text border-beige-outline py-3 px-4 w-full text-dark-blue
                            rounded-lg mb-2 shadow-un-click-card"
                            key={index}
                        >
                            {detailData}
                        </div>
                    ))}
                </div>
            )
        case BrandsCardType.EIBasicAssets:
            return (
                <div className="px-6 pb-6 pt-4 whitespace-pre-line font-subtitle">
                    <div className="flex flex-col gap-4">
                        <div className="flex flex-row items-center">
                            <div className="pr-1">Brand name:</div>
                            <div className="font-body-text">{data.Name}</div>
                        </div>
                        <div className="flex flex-row items-center">
                            <div className="pr-1">Logo:</div>
                            {data.Logo.length > 0 ? (
                                <NeedleImage
                                    alt="logo-brand"
                                    className="ml-6px h-12 border border-beige-outline rounded-md"
                                    src={handleFileData(data.Logo)}
                                />
                            ) : (
                                <div className="font-body-text">None</div>
                            )}
                        </div>
                        <div className="flex flex-row items-center">
                            <div className="pr-1">Brand guide:</div>
                            {data.Guide?.length > 0 ? (
                                <img
                                    alt="logo-brand"
                                    className="h-12 aspect-square"
                                    src="/assets/icons/brand-guide-icon.svg"
                                />
                            ) : (
                                <div className="font-body-text">None</div>
                            )}
                        </div>
                        <div className="flex flex-row items-center">
                            <div className="pr-1">Custom fonts:</div>
                            {data.Font.length > 0 ? (
                                <img
                                    alt="logo-brand"
                                    className="w-12 aspect-square"
                                    src="/assets/icons/font-image.svg"
                                />
                            ) : (
                                <div className="font-body-text">None</div>
                            )}
                        </div>
                    </div>
                </div>
            )
        case BrandsCardType.Customers:
            return (
                <div className="font-body-text px-6 pb-6 pt-6 whitespace-pre-line">
                    <div className="font-subtitle">Who are your customers?</div>
                    <div className="font-small-text py-2">
                        Describe them, as if you’re talking to a friend.
                    </div>
                    <div className="border border-beige-outline p-4 rounded-lg text-black font-body-text shadow-un-click-card">
                        {data.Describe}
                    </div>
                    <div className="font-subtitle text-dark-blue">
                        <div className="flex flex-row flex-wrap items-center pt-4">
                            <div className="pr-1">Age Group:</div>
                            <div className="font-body-text">
                                {data.AgeGroup.join(', ')}
                            </div>
                        </div>
                        <div className="flex flex-row flex-wrap items-center pt-4">
                            <div className="pr-1">Gender:</div>
                            <div className="font-body-text">
                                {data.Gender.length === GENDER_DATA.length
                                    ? 'All'
                                    : data.Gender.join(', ')}
                            </div>
                        </div>
                        <div className="flex flex-row flex-wrap items-center pt-4">
                            <div className="pr-1">Family status:</div>
                            <div className="font-body-text">
                                {data.Family.join(', ')}
                            </div>
                        </div>
                    </div>
                </div>
            )
        case BrandsCardType.MultipleSelectDropdown:
            return (
                <div className="flex flex-wrap flex-row pt-2 pb-6 px-6">
                    {data.map((detailData: OptionType) => (
                        <div
                            className="border border-beige-outline rounded-xl mr-2 py-2 px-3 flex flex-row justify-center 
                            items-center font-body-text mt-2"
                            key={detailData.value}
                        >
                            <img
                                src={getIconUrl(detailData.value)}
                                alt="checked-icon"
                                className="h-4 w-4 mr-2"
                            />
                            {detailData.label}
                        </div>
                    ))}
                </div>
            )
        case BrandsCardType.ProductSelect:
            return (
                <div className="flex flex-wrap flex-row pt-2 pb-6 px-6 gap-2">
                    {data.map((detailData: any) => (
                        <div
                            className="border border-beige-outline rounded-xl py-2 px-3 flex 
                            flex-row justify-center items-center font-body-text mt-2 w-205px"
                            key={detailData.value}
                        >
                            <NeedleImage
                                src={detailData.image}
                                alt="checked-icon"
                                className="h-12 w-12 mr-2 rounded-md"
                            />
                            <div className="line-clamp-2">
                                {detailData.label || detailData.title}
                            </div>
                        </div>
                    ))}
                </div>
            )
        case BrandsCardType.Link:
            return (
                <div className="font-body-text p-6 whitespace-pre-line flex flex-col gap-2">
                    {data.link !== '' && (
                        <div className="flex flex-row items-center">
                            <div className="font-subtitle pr-2">
                                Sample email link:
                            </div>
                            <div>{data.link}</div>
                        </div>
                    )}
                    {data.accessMail.length > 0 && (
                        <div className="flex flex-row items-center">
                            <div className="font-subtitle pr-2">
                                Access data:
                            </div>
                            <div>{data.accessMail.join(', ')}</div>
                        </div>
                    )}
                </div>
            )
        case BrandsCardType.LinkAndMedia:
            return (
                <div className="font-body-text p-6 whitespace-pre-line flex flex-col gap-2">
                    {data.link !== '' && (
                        <div className="flex flex-row items-center">
                            <div className="font-subtitle pr-2">
                                Sample media link:
                            </div>
                            <div>{data.link}</div>
                        </div>
                    )}
                    {data.media.length > 0 && (
                        <div className="flex flex-row items-center">
                            <div className="font-subtitle pr-2">
                                Media upload:
                            </div>
                            <div>{data.media.length}</div>
                        </div>
                    )}
                </div>
            )
        case BrandsCardType.Metrics:
            return (
                <div className="font-body-text p-6 whitespace-pre-line flex flex-col gap-2 text-dark-blue">
                    <div className="flex flex-row">
                        <div className="font-subtitle">
                            Marketing spend per month:
                        </div>
                        <div className="pl-1 flex items-center">
                            {currency}
                            {formatNumber(data.marketSpend)}
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <div className="font-subtitle">Gross margin:</div>
                        <div className="pl-1 flex items-center">
                            {data.grossMargin}%
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <div className="font-subtitle">
                            Shipping cost per order:
                        </div>
                        <div className="pl-1 flex items-center">
                            {currency}
                            {formatNumber(data.shippingCost)}
                        </div>
                    </div>
                </div>
            )
        case BrandsCardType.Text:
        default:
            return (
                <div className="font-body-text px-6 pb-6 pt-4 whitespace-pre-line text-black">
                    <div className="border border-beige-outline rounded p-4 shadow-un-click-card">
                        {data.toString()}
                    </div>
                </div>
            )
    }
}
export const handleFileData = (
    data: Needle.metaDataAssets[]
): string | undefined => {
    if (data[0]?.url) {
        return data[0].url
    } else {
        return undefined
    }
}
export default DataDisplayCard
