import { components } from 'react-select'

export enum ConceptType {
    Email = 'Email',
    Image = 'Image',
    Video = 'Video',
    CustomRequest = 'Custom Request',
    Blog = 'Blog',
}

export enum BlockedType {
    BrandProfile = 'Fill in brand profile essentials',
    EmailTemplates = 'Provide email templates',
    UploadMoreAssets = 'Upload additional assets',
}

export const MINIUM_PRODUCT_VIDEO = 4

export const CustomOptionSmall = (props: any) => {
    return (
        <components.Option {...props}>
            <div className="flex flex-row items-center">
                <div className="bg-white rounded-lg">
                    <img
                        src={props.data.image}
                        className="h-29px aspect-square"
                        alt={`google-drive`}
                    />
                </div>
                <div className="flex flex-col justify-center ml-2 font-body-text">
                    {props.data.label}
                </div>
            </div>
        </components.Option>
    )
}

export const SingleValue = (props: any) => {
    return (
        <components.SingleValue {...props}>
            <div className="flex flex-row items-center">
                <div className="bg-white rounded-lg">
                    <img
                        src={props.data.image}
                        className="h-29px aspect-square"
                        alt={`google-drive`}
                    />
                </div>
                <div className="flex flex-col justify-center ml-2 font-body-text">
                    {props.data.label}
                </div>
            </div>
        </components.SingleValue>
    )
}

export const customStylesDropDownAssets = {
    control: (provided: any, state: any) => ({
        ...provided,
        minHeight: '45px', // Decrease the height of the dropdown control
        height: '45px',
        borderColor: 'bg-dark-blue',
        borderRadius: '8px',
        boxShadow: 'none',
        '&:hover': {
            border: 'bg-black',
        },
    }),
    singleValue: (styles: any) => ({
        ...styles,
        display: 'flex',
        alignItems: 'center',
        fontSize: '12px',
    }),
    dropdownIndicator: (provided: any, state: any) => ({
        ...provided,
        paddingRight: '20px',
        color: 'black',
        svg: {
            height: '16px',
            width: '16px',
        },
    }),
    menuPortal: (provided: any) => ({
        ...provided,
        zIndex: 9999,
    }),
}
export const getLinkGoogleDrive = `
            <div>
                <p style="padding-bottom: 8px;">1. Upload additional images or videos into a Google Drive folder.</p>
                <p style="padding-bottom: 8px;">2. Select the folder and click  <strong>“Share”</strong>.</p>
                <p style="padding-bottom: 8px;">3. Share with  <strong>“access@askneedle.com”</strong> with the Role of  <strong>“Editor”</strong>.</p>
                <p>4. Click  <strong>“Copy link”</strong> on the share menu and paste the link below.</p>
            </div>`

export const getLinkDropbox = `
            <div>
                <p style="padding-bottom: 8px;">1. Upload additional images or videos into a Dropbox folder.</p>
                <p style="padding-bottom: 8px;">2. Select the folder and click  <strong>“Share selected”</strong>.</p>
                <p style="padding-bottom: 8px;">3. Share with <strong>“access@askneedle.com”</strong>, with the <strong>“can edit”</strong> access.</p>
                <p>4. Click <strong>“Create & copy link”</strong> on the share menu, then click <strong>“Copy link”</strong> and paste the link below.</p>
            </div>`
export const dataOptions = [
    {
        label: 'Google Drive',
        value: 'Google Drive',
        image: '/assets/icons/google-icon-block.png',
    },
    {
        label: 'Dropbox',
        value: 'Dropbox',
        image: '/assets/icons/dropbox-icon-block.png',
    },
]
