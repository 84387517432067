import React from 'react'
import { ButtonType, NeedleButton } from '../../../components/NeedleButton'
import { PATH } from '../../../constants/path'
import { useNavigate } from 'react-router-dom'

export const BasicAssetsBlocked = () => {
    const navigate = useNavigate()
    return (
        <div>
            <div className="font-header-3">Upload your brand assets</div>
            <div
                className="font-body-text pt-2 pb-6"
                dangerouslySetInnerHTML={{
                    __html: `
                    <div>
                            <p>Upload your brand assets (logos, fonts, etc.) on the
                            <strong>“Basic assets”</strong> 
                            section of the Assets & Templates page.</p>
                    </div>
                    `,
                }}
            />
            <div className="flex justify-end">
                <div>
                    <NeedleButton
                        buttonCharacter="Upload brand assets"
                        buttonType={ButtonType.Black}
                        onClickFunction={() =>
                            navigate(PATH.assetsAndTemplates)
                        }
                    />
                </div>
            </div>
        </div>
    )
}

export default BasicAssetsBlocked
