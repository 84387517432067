import {
    customStylesDropDownAAndT,
    getLinkDropboxEI,
    getLinkGoogleDriveEI,
} from './constants'
import Select from 'react-select'
import {
    CustomOptionSmall,
    SingleValue,
    dataOptions,
} from '../IdeasDetailPage/constants'
import { useEffect, useState } from 'react'
import { ButtonType, NeedleButton } from '../../components/NeedleButton'
import { EIAssets } from '../BrandProfilePage/type'
import DoneWithFlatForm from './DoneWithFlatForm'
import { connectToolType } from './type'
import { updateAssetsAndTemplates } from '../../hooks/useEnhanceYourAI'
interface AssetsLinkProps {
    setIsSubmit: (data: EIAssets[]) => void
    dataAssets: EIAssets[]
}
export const findOptionByValue = (value: string, list: any) => {
    const optionData = list.find((option: any) => option.value === value)
    return optionData ? optionData : null
}
function AssetsLink({ setIsSubmit, dataAssets }: AssetsLinkProps) {
    const [selectedOptions, setSelectedOptions] = useState<any>(
        findOptionByValue(dataAssets[0].platform, dataOptions)
    )
    const handleChange = (selected: any): void => {
        setSelectedOptions(selected)
        setDriveLink('')
    }
    const [driveLink, setDriveLink] = useState<string>(dataAssets[0].url)
    const [isDisable, setIsDisable] = useState<boolean>(
        dataAssets[0].url === ''
    )
    const [isOpen, setIsOpen] = useState<boolean>(
        dataAssets[0].url === '' ? true : false
    )
    useEffect(() => {
        setSelectedOptions(
            findOptionByValue(dataAssets[0].platform, dataOptions)
        )
        setDriveLink(dataAssets[0].url)
        setIsDisable(dataAssets[0].url === '')
        setIsOpen(dataAssets[0].url === '' ? true : false)
    }, [dataAssets])
    useEffect(() => {
        if (driveLink.trim() === '') {
            setIsDisable(true)
        } else {
            setIsDisable(false)
        }
    }, [driveLink])

    const submitSharedAccess = async () => {
        const saveData: EIAssets[] = [
            {
                platform: selectedOptions.value,
                url: driveLink,
            },
            ...dataAssets,
        ]
        const data = await updateAssetsAndTemplates({
            assets: saveData,
        })
        if (data) {
            setIsSubmit(saveData)
            setIsOpen(false)
        }
    }

    return (
        <div className="bg-white rounded-lg mt-4 p-6 shadow-un-click-card">
            <div className="font-header-3">Share access to brand assets</div>
            <div
                className="font-body-text pt-2 pb-6"
                dangerouslySetInnerHTML={{
                    __html: `Share your <strong>Google Drive</strong> or <strong>Dropbox</strong> folder so that Needle can access your brand’s images and videos.
<strong>Make sure to set it to “Anyone with the link can view”</strong> - that way, we can dive right in and start creating your assets.`,
                }}
            />
            <div className="h-full w-full transition-colors text-dark-blue">
                {isOpen ? (
                    <div>
                        <Select
                            isClearable={false}
                            options={dataOptions}
                            isSearchable={false}
                            value={selectedOptions}
                            onChange={handleChange}
                            placeholder={
                                <div className="font-body-text text-dark-blue">
                                    Select app
                                </div>
                            }
                            components={{
                                SingleValue,
                                IndicatorSeparator: () => null,
                                Option: CustomOptionSmall,
                            }}
                            styles={customStylesDropDownAAndT}
                            backspaceRemovesValue={false}
                        />
                        {selectedOptions && (
                            <div className="pt-4">
                                <div className="font-subtitle">
                                    Instructions:
                                </div>
                                <div
                                    className="font-body-text pt-2"
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            selectedOptions.value ===
                                            'Google Drive'
                                                ? getLinkGoogleDriveEI
                                                : getLinkDropboxEI,
                                    }}
                                />
                                <textarea
                                    rows={1}
                                    id="first_name"
                                    className="border border-beige-outline font-body-text
                             py-3 px-4 w-full rounded-lg focus:outline-dark-blue mt-2"
                                    placeholder={`Paste ${selectedOptions.value} folder link`}
                                    value={driveLink}
                                    onChange={(e) =>
                                        setDriveLink(e.target.value)
                                    }
                                />
                                <div className="font-link-small-text text-blue-theme underline ">
                                    See video guide
                                </div>
                                <div className="flex justify-end pt-2">
                                    <div>
                                        <NeedleButton
                                            buttonCharacter="I've shared access"
                                            buttonType={ButtonType.Black}
                                            onClickFunction={submitSharedAccess}
                                            isDisable={isDisable}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="flex">
                        <DoneWithFlatForm
                            type={
                                selectedOptions?.value === 'Google Drive'
                                    ? connectToolType.Google
                                    : connectToolType.Dropbox
                            }
                            setIsOpen={() => setIsOpen(true)}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default AssetsLink
