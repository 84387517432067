import React, { useEffect, useState } from 'react'
import './TextSection.scss'
import Select, { components } from 'react-select'
import { OptionType } from '../../views/BrandProfilePage/type'
import { MARKET_OPTIONS } from '../../constants/countries'
import { NeedleImage } from '../NeedleImage'
import productsStore, { getProductData } from '../../storeZustand/productsStore'
import {
    CustomOptionBig,
    CustomOptionNormal,
    customStyles,
    getIconUrl,
} from './component'
import { formatToOptionTypeProduct } from '../../storeZustand/brandCustomizationStore'
interface CardTypeSelectDropDownProps {
    setCurrentData: (value: React.SetStateAction<any>) => void
    currentData: any
    setIsDisable: React.Dispatch<React.SetStateAction<boolean>>
    isProductType?: boolean
    isOnboarding?: boolean
}
const MAX_ONBOARDING_VALUE_FLAG = 2
function CardTypeSelectDropDown({
    setCurrentData,
    currentData,
    setIsDisable,
    isOnboarding = false,
    isProductType = false,
}: CardTypeSelectDropDownProps) {
    const [selectedOptions, setSelectedOptions] =
        useState<OptionType[]>(currentData)
    const [displayMarket, setDisplayMarket] = useState<boolean>(true)
    const [dataOptions, setDataOptions] = useState<OptionType[]>(
        isProductType ? [] : MARKET_OPTIONS
    )
    const { products, isLoadedProducts } = productsStore((state) => ({
        products: state.products,
        isLoadedProducts: state.isLoadedProducts,
    }))

    let imageSize = 'h-4 w-4'
    let placeHolder = 'Add market'
    let customOption = CustomOptionNormal

    if (isProductType) {
        imageSize = 'h-12 w-12'
        placeHolder = 'Add product'
        customOption = CustomOptionBig
    }

    const handleChange = (selected: any): void => {
        if (
            !(
                isOnboarding &&
                selectedOptions.length > MAX_ONBOARDING_VALUE_FLAG
            )
        ) {
            setSelectedOptions(selected || [])
        }
    }

    const handleRemove = (selected: any): void => {
        const newSelectOptions = selectedOptions.filter(
            (option: any) =>
                (option.value || option.id) !== (selected.value || selected.id)
        )

        setSelectedOptions(newSelectOptions)
    }
    const MultiValue = () => null

    useEffect(() => {
        const currentSelectedOptionss = !isLoadedProducts
            ? selectedOptions
            : selectedOptions.map((option: any) =>
                  products.find(
                      (product: any) =>
                          Number(option.value || option.id) ===
                          Number(product.id)
                  )
              )
        if (currentSelectedOptionss.length > 0 || isOnboarding) {
            setIsDisable(false)
            setCurrentData(currentSelectedOptionss)
        } else {
            setIsDisable(true)
        }
    }, [selectedOptions, products])

    useEffect(() => {
        if (isProductType) {
            getProductData()
        }
    }, [])

    useEffect(() => {
        if (isProductType) {
            setDataOptions(formatToOptionTypeProduct(products))
        }
    }, [products])

    return (
        <div
            className={`${isOnboarding ? 'mt-4' : 'mx-6 mt-6 mb-4'} font-body-text`}
        >
            <div className="relative">
                <Select
                    isMulti
                    isClearable={false}
                    options={dataOptions}
                    menuPortalTarget={document.body}
                    value={selectedOptions}
                    onChange={handleChange}
                    placeholder=""
                    isLoading={isProductType && !isLoadedProducts}
                    components={{
                        MultiValue,
                        IndicatorSeparator: () => null,
                        Option: customOption,
                    }}
                    loadingMessage={() => 'Fetching options...'}
                    styles={customStyles}
                    className="basic-multi-select relative"
                    classNamePrefix="select"
                    backspaceRemovesValue={false}
                    onFocus={() => setDisplayMarket(false)}
                    onBlur={() => setDisplayMarket(true)}
                    closeMenuOnSelect={false}
                    menuPlacement="top"
                />
                <label
                    htmlFor="market-select"
                    className="text-black absolute bottom-3 left-4 font-dm"
                >
                    {displayMarket ? placeHolder : ''}
                </label>
            </div>
            <div className="flex flex-wrap flex-row pt-2px">
                {selectedOptions.map((option: any) => (
                    <div key={option.value} className="pr-2 pt-2">
                        <div
                            className={`border border-beige-outline rounded-xl py-2 px-3 flex flex-row 
            justify-between items-center font-body-text bg-white ${isProductType ? 'w-228px' : ''}`}
                        >
                            <div className="flex items-center">
                                <NeedleImage
                                    src={
                                        isProductType
                                            ? option.image
                                            : getIconUrl(option.value)
                                    }
                                    alt="checked-icon"
                                    className={`${imageSize} mr-2 rounded-md`}
                                />
                                <div className="line-clamp-2">
                                    {option.label || option.title}
                                </div>
                            </div>
                            <div
                                onClick={() => handleRemove(option)}
                                className="w-4 h-4 flex-shrink-0 ml-2 cursor-pointer"
                            >
                                <img
                                    src="/assets/icons/close-icon.svg"
                                    alt="close-icon"
                                    className="h-4 w-4"
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default CardTypeSelectDropDown
