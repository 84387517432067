import { ASSETS_AND_TEMPLATES_SECTIONS } from './constants'
import brandStore from '../../storeZustand/brandCustomizationStore'
import {
    SmartAITool,
    SmartAIToolType,
} from '../../components/CoverBox/SmartAITool'
import { CoverBox } from '../../components/CoverBox/CoverBox'
import { DropdownListView } from '../HomeScreen/DropdownListView'
import AssetsLink from './AssetsLink'
import { BrandsCardType, EIAssets } from '../BrandProfilePage/type'
import TemplatesEmail from './TemplatesEmail/TemplatesEmail'
import { usePageTitle } from '../HomeScreen/component'

function AssetsAndTemplatesPage() {
    usePageTitle('Needle - Assets And Templates')
    const { aAndTBasicAssets, aAndTEmail, aAndTAssets } = brandStore(
        (state) => ({
            aAndTBasicAssets: state.aAndTBasicAssets,
            aAndTEmail: state.aAndTEmail,
            aAndTAssets: state.aAndTAssets,
        })
    )
    const { setValueAssets } = brandStore((state) => ({
        setValueAssets: state.setValueAssets,
    }))
    const listClear = [
        aAndTBasicAssets.Logo.length > 0,
        aAndTAssets[0].url !== '',
        aAndTEmail.FromScratch || aAndTEmail.Klaviyo !== null,
    ]

    const setIsSubmit = (data: EIAssets[]) => {
        setValueAssets(data)
    }

    return (
        <div className="bg-beige h-full w-full transition-colors text-dark-blue">
            <div className="flex flex-col justify-center items-center w-full mb-14">
                <div className="flex flex-col w-4/5 sm:w-5/6 max-w-728px items-center">
                    <div className="flex w-full pb-2 flex-col justify-center items-center">
                        <SmartAITool
                            pageWord={`Assets & Templates`}
                            typePage={SmartAIToolType.detailPage}
                        />
                        <div className="font-body-text pt-2">
                            Images, videos, and templates to help Needle create
                            new creatives
                        </div>
                    </div>
                    <CoverBox
                        name={ASSETS_AND_TEMPLATES_SECTIONS.name}
                        connectStatus={{
                            currentConnect: listClear[0] ? 1 : 0,
                            maxConnect: 1,
                        }}
                        index={0}
                        keyFinish="Completed"
                        isDropDown={false}
                    >
                        <DropdownListView
                            itemList={[
                                {
                                    name: 'Brand details',
                                    value: {
                                        Name: '',
                                        logo: {
                                            url: 'string',
                                            metadata: {
                                                name: 'string',
                                                size: 20, // in KB
                                            },
                                        },
                                        guide: {
                                            url: 'string',
                                            metadata: {
                                                name: 'string',
                                                size: 20, // in KB
                                            },
                                        },
                                        customFont: {
                                            url: 'string',
                                            metadata: {
                                                name: 'string',
                                                size: 20, // in KB
                                            },
                                        },
                                    },
                                    stateKey: 'aAndTBasicAssets',
                                    type: BrandsCardType.EIBasicAssets,
                                },
                            ]}
                            isShowView={false}
                            isHoverAnimated={false}
                        />
                    </CoverBox>
                    <CoverBox
                        name={'Assets'}
                        connectStatus={{
                            currentConnect: listClear[1] ? 1 : 0,
                            maxConnect: 1,
                        }}
                        index={0}
                        keyFinish="Shared"
                        isDropDown={false}
                    >
                        <AssetsLink
                            setIsSubmit={setIsSubmit}
                            dataAssets={aAndTAssets}
                        />
                    </CoverBox>
                    <CoverBox
                        name={'Email templates'}
                        connectStatus={{
                            currentConnect: listClear[2] ? 1 : 0,
                            maxConnect: 1,
                        }}
                        index={0}
                        keyFinish="Shared"
                        isDropDown={false}
                    >
                        <TemplatesEmail />
                    </CoverBox>
                </div>
            </div>
        </div>
    )
}
export default AssetsAndTemplatesPage
