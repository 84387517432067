import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { customStylesDropDownAAndT } from '../AssetsAndTemplates/constants'
import { CreativeIdea } from './type'
import { CustomOptionAddIdea, ValueAddIdea } from './function'
import useCreativeIdeas from '../../hooks/useCreativeIdeas'
interface SelectOutputProps {
    selectedType: any
    setSelectedType: any
}
export const SelectOutput = ({
    selectedType,
    setSelectedType,
}: SelectOutputProps) => {
    const { data: createtiveData, isLoading } = useCreativeIdeas()
    const [outputIdeaOption, setOutputIdeaOption] = useState<CreativeIdea[]>([])

    useEffect(() => {
        if (createtiveData) {
            setOutputIdeaOption(createtiveData)
        }
    }, [createtiveData])

    const handleChange = (selected: any): void => {
        setSelectedType(selected)
    }

    return (
        <div className="p-6">
            <div className="font-header-3">Select creative type</div>
            <div className="font-small-text pt-2 pb-6">
                Choose the kind of asset you want Needle to create for you
            </div>
            <Select
                isClearable={false}
                options={outputIdeaOption}
                isSearchable={false}
                value={selectedType}
                onChange={handleChange}
                isLoading={isLoading}
                getOptionValue={(option) => option.key}
                loadingMessage={() => 'Fetching options...'}
                placeholder={
                    <div className="font-body-text text-dark-blue">
                        Select output
                    </div>
                }
                components={{
                    SingleValue: ValueAddIdea,
                    IndicatorSeparator: () => null,
                    Option: CustomOptionAddIdea,
                }}
                styles={customStylesDropDownAAndT}
                backspaceRemovesValue={false}
            />
            {selectedType && (
                <div className="border border-beige-outline rounded text-sm px-4 py-3 bg-beige mt-2">
                    Note: the cost of{' '}
                    <strong>
                        {selectedType?.credits} Credit
                        {selectedType?.credits > 1 ? 's' : ''}
                    </strong>{' '}
                    is an estimate — Needle will reach out to you if your
                    concept requires more credits.
                </div>
            )}
        </div>
    )
}

export default SelectOutput
