import React, { useEffect, useState } from 'react'
import { SmartAITool } from '../../components/CoverBox/SmartAITool'
import { OptionType } from '../BrandProfilePage/type'
import { ConceptType } from '../IdeasDetailPage/constants'
import MultipleProductSelect from './MultipleProductSelect'
import { ButtonType, NeedleButton } from '../../components/NeedleButton'
import DatePicker from 'react-datepicker'
import { useNavigate } from 'react-router-dom'
import SelectOutput from './SelectOutput'
import ReviewIdeaSummary from './ReviewIdeaSummary'
import {
    formatToOptionTypeProduct,
    formatToProductFromOption,
} from '../../storeZustand/brandCustomizationStore'
import { TextareaAutosize } from '@mui/base/TextareaAutosize/TextareaAutosize'
import { format } from 'date-fns'
import { createAnIdea } from '../../hooks/useIdeaDetail'
import { CreativeIdea } from './type'
import { PATH } from '../../constants/path'
import { usePageTitle } from '../HomeScreen/component'
import useGrowthPlan from '../../hooks/useGrowthPlan'

export const AddAnIdeaPage = () => {
    usePageTitle('Needle - Add An Idea')
    const navigate = useNavigate()
    const [selectedType, setSelectedType] = useState<CreativeIdea | undefined>()
    const { reloadGrowthPlan } = useGrowthPlan()
    const [ideaTitle, setIdeaTitle] = useState<string>('')
    const [ideaDescription, setIdeaDescription] = useState<string>('')
    const [productSelect, setProductSelect] = useState<OptionType[]>(
        formatToOptionTypeProduct([])
    )
    const [openDetailConcept, setOpenDetailConcept] = useState<boolean>(false)
    const [isDisableReviewButton, setIsDisableReviewButton] =
        useState<boolean>(true)
    const [descriptionScript, setDescriptionScript] = useState<string>('')
    const [descriptionPlaceHolder, setDescriptionPlaceHolder] =
        useState<string>('')
    // const [shouldSendDate, setShouldSendDate] = useState<Date>(() => {
    //     const currentDate = new Date()
    //     currentDate.setDate(currentDate.getDate() + 2)
    //     return currentDate
    // })
    // const [conceptPostCaption, setConceptPostCaption] = useState<string>('')
    // const [conceptMoodBoardImages, setConceptMoodBoardImages] = useState<
    //     File[]
    // >([])
    // const [openReviewSummary, setOpenReviewSummary] = useState<boolean>(false)

    const addIdea = async () => {
        if (selectedType) {
            const newIdea = {
                idea_title: ideaTitle,
                idea_description: ideaDescription,
                concepts: [
                    {
                        title: ideaTitle,
                        description: ideaDescription,
                        creative_type: selectedType.creative_type,
                        asset_type_id: selectedType.id,
                        asset_type_name: selectedType.name,
                        credits: selectedType.credits,
                        // suggested_send_date: format(
                        //     shouldSendDate,
                        //     "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
                        // ),
                        // post_caption: conceptPostCaption,
                        products: formatToProductFromOption(productSelect),
                    },
                ],
            }
            const dataReturn = await createAnIdea(newIdea)
            if (dataReturn?.id) {
                navigate(PATH.ideasDetail.replace(':id', dataReturn.id), {
                    state: {
                        userNewIdea: true,
                    },
                })
                reloadGrowthPlan()
            }
        }
    }

    useEffect(() => {
        if (selectedType?.creative_type) {
            setOpenDetailConcept(true)
            switch (selectedType.creative_type) {
                case ConceptType.Email:
                    setDescriptionPlaceHolder('Describe the email campaign')
                    setDescriptionScript(
                        'Describe the objective, contents, and direction of this email campaign.'
                    )
                    if (
                        ideaTitle.trim() !== '' &&
                        ideaDescription.trim() !== ''
                    ) {
                        setIsDisableReviewButton(false)
                    } else {
                        setIsDisableReviewButton(true)
                        // setOpenReviewSummary(false)
                    }
                    break
                case ConceptType.Video:
                    setDescriptionPlaceHolder('Describe the video')
                    setDescriptionScript(
                        'Describe the objective, contents, and direction of this video.'
                    )
                    if (
                        ideaTitle.trim() !== '' &&
                        ideaDescription.trim() !== ''
                    ) {
                        setIsDisableReviewButton(false)
                    } else {
                        setIsDisableReviewButton(true)
                        // setOpenReviewSummary(false)
                    }
                    break
                case ConceptType.Image:
                    setDescriptionPlaceHolder('Describe the image(s)')
                    setDescriptionScript(
                        'Describe the objective, contents, and direction of the image(s).'
                    )
                    if (
                        ideaTitle.trim() !== '' &&
                        ideaDescription.trim() !== ''
                        // conceptPostCaption.trim() !== ''
                    ) {
                        setIsDisableReviewButton(false)
                    } else {
                        setIsDisableReviewButton(true)
                        // setOpenReviewSummary(false)
                    }
                    break
                default:
                    setDescriptionPlaceHolder(
                        `Describe the ${selectedType.creative_type} campaign`
                    )
                    setDescriptionScript(
                        `Describe the objective, contents, and direction of this ${selectedType.creative_type} campaign.`
                    )
                    if (
                        ideaTitle.trim() !== '' &&
                        ideaDescription.trim() !== ''
                    ) {
                        setIsDisableReviewButton(false)
                    } else {
                        setIsDisableReviewButton(true)
                        // setOpenReviewSummary(false)
                    }
                    break
            }
        } else {
            setIsDisableReviewButton(true)
            setOpenDetailConcept(false)
        }
    }, [
        productSelect,
        ideaTitle,
        ideaDescription,
        selectedType,
        // conceptPostCaption,
        // conceptMoodBoardImages,
    ])

    return (
        <div className="bg-beige h-full w-full transition-colors text-dark-blue">
            <SmartAITool pageWord={`Add an Idea`} />
            <div className="font-body-text justify-center flex pt-2">
                Create your own Idea from scratch
            </div>
            <div className="flex flex-col justify-center items-center w-full mb-14 pt-8 font-body-text">
                <div className="flex flex-col w-680px rounded-lg bg-white shadow-un-click-card">
                    <SelectOutput
                        selectedType={selectedType}
                        setSelectedType={setSelectedType}
                    />
                    {openDetailConcept && (
                        <div className="p-6 relative">
                            <div className="bg-beige-outline h-1px w-full absolute left-0 top-0" />
                            <div className="font-header-3">
                                Describe this{' '}
                                {selectedType?.creative_type?.toLowerCase()}{' '}
                                concept
                            </div>
                            <div className="pt-6">
                                <div className="font-subtitle pb-2">
                                    Title{/* */}
                                    <span className="text-red-theme">*</span>
                                </div>
                                <input
                                    id="concept-title"
                                    className="textarea-box-needle"
                                    placeholder={'Concept title'}
                                    value={ideaTitle}
                                    onChange={(e) =>
                                        setIdeaTitle(e.target.value)
                                    }
                                />
                            </div>
                            <div className="pt-6">
                                <div className="font-subtitle pb-2">
                                    Description{/* */}
                                    <span className="text-red-theme">*</span>
                                </div>
                                <div className="font-small-text pb-2">
                                    {descriptionScript}{' '}
                                    <span className="text-red-theme">
                                        Include links to visual references
                                    </span>
                                </div>
                                <TextareaAutosize
                                    id="concept-description"
                                    className="textarea-box-needle"
                                    placeholder={descriptionPlaceHolder}
                                    value={ideaDescription}
                                    onChange={(e) =>
                                        setIdeaDescription(e.target.value)
                                    }
                                />
                            </div>
                            {/* {selectedType?.type === ConceptType.Image && (
                                <div className="pt-6">
                                    <div className="font-subtitle pb-2">
                                        Post caption
                                        <span className="text-red-theme">
                                            *
                                        </span>
                                    </div>
                                    <div className="font-small-text pb-2">
                                        Write out the post caption that will
                                        accompany the image(s)
                                    </div>
                                    <TextareaAutosize
                                        id="post-caption"
                                        className="textarea-box-needle"
                                        placeholder={'Post caption'}
                                        value={conceptPostCaption}
                                        onChange={(e) =>
                                            setConceptPostCaption(
                                                e.target.value
                                            )
                                        }
                                    />
                                </div>
                            )} */}
                            <MultipleProductSelect
                                setSelectedProduct={setProductSelect}
                                selectedProduct={productSelect}
                            />
                            {/* {selectedType?.type === ConceptType.Email && (
                                <div>
                                    <div className="font-subtitle pt-6">
                                        Suggested send date
                                        <span className="text-red-theme">
                                            *
                                        </span>
                                    </div>
                                    <DatePicker
                                        selected={shouldSendDate}
                                        onChange={(date) => {
                                            if (date) {
                                                setShouldSendDate(date)
                                            }
                                        }}
                                        placeholderText="Select date"
                                        dateFormat="MMMM dd, yyyy (EEEE)"
                                        className="border border-beige-outline rounded-lg px-4 mt-2 flex items-center focus:outline-none py-3 w-632px"
                                    />
                                </div>
                            )}
                            {selectedType?.type === ConceptType.Video && (
                                <MoodBoardUpload
                                    conceptMoodBoardImages={
                                        conceptMoodBoardImages
                                    }
                                    setConceptMoodBoardImages={
                                        setConceptMoodBoardImages
                                    }
                                />
                            )}
                            {!openReviewSummary && (
                                <div className="pt-6 flex justify-end">
                                    <div>
                                        <NeedleButton
                                            buttonCharacter="Next step"
                                            onClickFunction={() =>
                                                setOpenReviewSummary(true)
                                            }
                                            buttonType={ButtonType.Black}
                                            isDisable={isDisableReviewButton}
                                        />
                                    </div>
                                </div>
                            )} */}
                            <div className="pt-6 flex justify-end">
                                <div>
                                    <NeedleButton
                                        buttonCharacter="Add idea"
                                        onClickFunction={addIdea}
                                        buttonType={ButtonType.Black}
                                        isDisable={isDisableReviewButton}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    {/* {openReviewSummary && (
                        <ReviewIdeaSummary
                            ideaTitle={ideaTitle}
                            setIdeaTitle={setIdeaTitle}
                            ideaDescription={ideaDescription}
                            setIdeaDescription={setIdeaDescription}
                            onAddIdea={addIdea}
                        />
                    )} */}
                </div>
            </div>
        </div>
    )
}
export default AddAnIdeaPage
