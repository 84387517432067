import JSZip from 'jszip'
import { EventType } from '../HomeScreen/constants'
import EmailAssetItem from './AssetItem/EmailAssetItem'
import ImagesAssetItem from './AssetItem/ImagesAssetItem'
import { ConceptType } from './constants'
import saveAs from 'file-saver'
import VideoAssetItem from './AssetItem/VideoAssetItem'
import {
    AssetsData,
    Concept,
    Idea,
    IdeaBlockerType,
} from '../../utils/needleEnum'
import { formatNumber } from '../../components/DetailCardView/component'
const imageRegex = /image\/(png|svg|[a-zA-Z0-9]+)/

export const AssetItem = (
    conceptsType: ConceptType,
    currentIdeas: Concept,
    ideasStatus: EventType,
    assetsData: AssetsData,
    deliveryText: string
): JSX.Element => {
    const formattedText = addStylesToHtmlString(deliveryText)
    switch (conceptsType) {
        case ConceptType.Email:
            return (
                <EmailAssetItem
                    currentIdeas={currentIdeas}
                    ideasStatus={ideasStatus}
                    assetsData={assetsData}
                    deliveryText={formattedText}
                />
            )
        case ConceptType.Image:
            return (
                <ImagesAssetItem
                    currentIdeas={currentIdeas}
                    ideasStatus={ideasStatus}
                    assetsData={assetsData}
                    deliveryText={formattedText}
                />
            )
        case ConceptType.Video:
            if (imageRegex.test(assetsData.files?.[0]?.mimeType)) {
                return (
                    <ImagesAssetItem
                        currentIdeas={currentIdeas}
                        ideasStatus={ideasStatus}
                        assetsData={assetsData}
                        deliveryText={formattedText}
                    />
                )
            }
            return (
                <VideoAssetItem
                    currentIdeas={currentIdeas}
                    ideasStatus={ideasStatus}
                    assetsData={assetsData}
                    deliveryText={formattedText}
                />
            )
        default:
            return (
                <EmailAssetItem
                    currentIdeas={currentIdeas}
                    ideasStatus={ideasStatus}
                    assetsData={assetsData}
                    deliveryText={formattedText}
                />
            )
    }
}
function addStylesToHtmlString(htmlString: string = ''): string {
    const container = document.createElement('div')
    container.innerHTML = htmlString?.trim()

    const paragraphs = container.getElementsByTagName('p')
    for (let i = 0; i < paragraphs.length; i++) {
        const p = paragraphs[i] as HTMLElement
        p.style.marginBottom = '12px'
    }

    const uls = container.getElementsByTagName('ul')
    for (let i = 0; i < uls.length; i++) {
        const ul = uls[i] as HTMLElement
        ul.style.marginLeft = '30px'
    }

    const links = container.getElementsByTagName('a')
    for (let i = 0; i < links.length; i++) {
        const a = links[i] as HTMLElement
        a.style.color = '#0080F7'
        a.style.textDecoration = 'underline'
    }

    const lis = container.getElementsByTagName('li')
    for (let i = 0; i < lis.length; i++) {
        const li = lis[i] as HTMLElement
        li.style.margin = '12px 0'
    }

    return container.innerHTML
}

export const handleDownloadAssets = async (dataAssets: string[]) => {
    const zip = new JSZip()

    const fetchImage = async (url: any) => {
        const response = await fetch(url)
        const blob = await response.blob()
        const fileName = url.substring(url.lastIndexOf('/') + 1)
        zip.file(fileName, blob)
    }

    await Promise.all(dataAssets.map((url: any) => fetchImage(url)))

    zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, 'images.zip')
    })
}

export const setupDataDisplay = (
    currentIdeas: Idea,
    setCurrentIdea: any
): void => {
    let revenueData = ` - / mth`
    if (typeof currentIdeas.idea_revenue === 'number') {
        revenueData = ` ${formatNumber(Math.round(currentIdeas.idea_revenue / 100) * 100)} / mth`
    }
    setCurrentIdea({
        title: currentIdeas.idea_title,
        description: currentIdeas.idea_description,
        revenueImpact: revenueData,
        whyDoThis: currentIdeas.idea_why,
        app: 'instagrams',
    })
}

export const getBlockedText = (
    blockedList: IdeaBlockerType[] | undefined
): string => {
    if (!blockedList) return 'Not Defined'
    if (blockedList.length > 1) {
        return 'Blocked: Multiple actions needed'
    } else {
        switch (blockedList[0]) {
            case IdeaBlockerType.profile_essentials:
                return 'Blocked: Fill in brand profile'

            case IdeaBlockerType.email_templates:
                return 'Blocked: Share email templates'

            case IdeaBlockerType.additional_assets:
                return 'Blocked: Upload additional assets'
            case IdeaBlockerType.basic_assets:
                return 'Blocked: Upload brand assets'
        }
    }
}
