import React, { useState } from 'react'
import { TypeForm } from './type'
import { NeedleButton } from '../../components/NeedleButton'

const GOOD_REVIEW = [
    'It’s a new and interesting insight',
    'It’s a channel I believe could work for me',
    'It’s a new angle I haven’t tried before',
]
const GOOD_DESCRIPTION = 'What did you like about it?'
const MEH_REVIEW = [
    'I’ve tried this before, it’s not new to me',
    'The insights presented are not new to me',
    'I don’t agree or fully trust the insights',
    'This channel doesn’t work for me',
]
const MEH_DESCRIPTION = 'Sorry to hear that. Why did it not work?'
const GOOD_CUSTOM_PLACEHOLDER = 'I like it because...'
const BAD_CUSTOM_PLACEHOLDER = `I don't like this because...`
const GOOD_FEEDBACK = 'positive'
const BAD_FEEDBACK = 'negative'
interface ReviewUserInputDataprops {
    ideasID: number
    isOpenForm: TypeForm
    setIsOpenForm: React.Dispatch<React.SetStateAction<TypeForm>>
    onSubmit: (data: any) => any
    isOpenCustomReview: boolean
    setIsOpenCustomReview: React.Dispatch<React.SetStateAction<boolean>>
    isLoading: boolean
}

function ReviewUserInputData({
    ideasID,
    isOpenForm,
    setIsOpenForm,
    onSubmit,
    isOpenCustomReview,
    setIsOpenCustomReview,
    isLoading,
}: ReviewUserInputDataprops) {
    const sampleData: string[] =
        isOpenForm === TypeForm.Good ? GOOD_REVIEW : MEH_REVIEW
    const button_state: string =
        isOpenForm === TypeForm.Good ? GOOD_FEEDBACK : BAD_FEEDBACK
    const customPlaceHolder: string =
        isOpenForm === TypeForm.Good
            ? GOOD_CUSTOM_PLACEHOLDER
            : BAD_CUSTOM_PLACEHOLDER
    const [description, setDescription] = useState<string>(
        isOpenForm === TypeForm.Good ? GOOD_DESCRIPTION : MEH_DESCRIPTION
    )
    const [userReview, setUserReview] = useState<string>('')

    const onBackButtonCLick = (): void => {
        if (isOpenCustomReview) {
            setIsOpenCustomReview(false)
            setDescription(
                isOpenForm === TypeForm.Good
                    ? GOOD_DESCRIPTION
                    : MEH_DESCRIPTION
            )
        } else {
            setIsOpenForm(TypeForm.NoSelected)
        }
    }

    const openCustomReview = (): void => {
        setDescription('Other')
        setIsOpenCustomReview(true)
        setUserReview('')
    }

    return (
        <div className="relative min-w-[360px] flex justify-center items-center flex-col font-body-text">
            <div
                className="text-center items-center absolute top-0 left-0 flex cursor-pointer"
                onClick={onBackButtonCLick}
            >
                <img src="/assets/icons/back-icon.svg" alt="back-icon" />
                <div className="pl-2 text-dark-blue font-dm text-sm font-bold">
                    Back
                </div>
            </div>
            <div className="font-subtitle text-black">Review: {isOpenForm}</div>
            <div className="pt-4 text-black">{description}</div>
            {isOpenCustomReview ? (
                <div className="w-full py-2 flex">
                    <textarea
                        rows={4}
                        id="custom-review"
                        className="border border-beige-outline font-body-text 
                            py-3 px-4 w-full rounded-lg focus:outline-dark-blue"
                        placeholder={customPlaceHolder}
                        value={userReview}
                        onChange={(e) => setUserReview(e.target.value)}
                    />
                </div>
            ) : (
                <div className="flex flex-col font-button-text gap-2 py-2 w-full">
                    {sampleData.map((sample: string) => (
                        <div
                            className={`text-dark-blue py-3 border rounded border-black px-4 cursor-pointer 
                        ${sample === userReview && 'border-green-theme outline-2 outline outline-green-theme'}
                        `}
                            key={sample}
                            onClick={() => setUserReview(sample)}
                        >
                            {sample}
                        </div>
                    ))}
                    <div
                        className={`text-dark-blue py-3 border rounded border-black px-4 cursor-pointer `}
                        onClick={openCustomReview}
                    >
                        Other
                    </div>
                </div>
            )}
            <div className="w-full">
                <NeedleButton
                    onClickFunction={() =>
                        onSubmit({
                            feedback_type: button_state,
                            message: userReview,
                        })
                    }
                    buttonCharacter="Submit"
                    isDisable={userReview.trim() === ''}
                    isFull={true}
                    isLoading={isLoading}
                />
            </div>
        </div>
    )
}

export default ReviewUserInputData
