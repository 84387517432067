import { create } from 'zustand'
import {
    LinkAndMediaType,
    LinkType,
    OptionType,
    OurCustomers,
    BrandMetricsType,
    EIBasicAssets,
    EIAssets,
    EITemplatesEmail,
} from '../views/BrandProfilePage/type'
import { MARKET_OPTIONS } from '../constants/countries'
import {
    updateAssetsAndTemplates,
    updateBrandProfile,
} from '../hooks/useEnhanceYourAI'
export interface BrandStoreProps {
    aAndTBasicAssets: EIBasicAssets
    aAndTAssets: EIAssets[]
    aAndTEmail: EITemplatesEmail
    brandVEMail: LinkType
    brandVSocial: LinkAndMediaType
    brandVMarket: LinkAndMediaType
    brandPStart: string
    brandPTell: string[]
    brandPDescribe: string
    brandPCount: number
    customersWho: OurCustomers
    customerServed: OptionType[]
    customerCount: number
    brandIUnique: string
    brandIProduct: string
    brandMetrics: BrandMetricsType
    brandMCount: number
    productToFeature: OptionType[]
    productToCount: number

    setValueAssets: (data: EIAssets[]) => void
    setTemplateEmail: (key: keyof EITemplatesEmail, data: any) => any
    setValue: (key: any, data: any) => any

    setupBrandProfile: (data: any) => void
    setupAssetsTemplates: (data: any) => void
}

const brandStore = create<BrandStoreProps>((set, get) => ({
    aAndTBasicAssets: {
        Name: '',
        Guide: [],
        Logo: [],
        Font: [],
        no_guidelines: false,
        no_fonts: false,
    },
    aAndTAssets: [{ platform: '', url: '' }],
    aAndTEmail: {
        FromScratch: false,
        Figma: '',
        Canva: '',
        Klaviyo: '',
    },

    brandVEMail: { link: '', accessMail: [] },
    brandVSocial: { link: '', media: [] },
    brandVMarket: { link: '', media: [] },

    brandPStart: '',
    brandPTell: [''],
    brandPDescribe: '',
    brandPCount: 0,
    customersWho: { Describe: '', Gender: [], AgeGroup: [], Family: [] },
    customerServed: [],
    customerCount: 0,
    brandIUnique: '',
    brandIProduct: '',
    brandMetrics: {
        marketSpend: undefined,
        grossMargin: undefined,
        shippingCost: undefined,
    },
    brandMCount: 0,
    productToFeature: [],
    productToCount: 0,

    setupBrandProfile: (dataBrandProfile: Needle.BrandProfile): void => {
        try {
            set({
                brandPTell: dataBrandProfile.brand_input
                    ?.brand_key_messages ?? [''],
                brandMetrics: {
                    marketSpend:
                        dataBrandProfile.brand_metrics?.marketing_spend,
                    grossMargin:
                        dataBrandProfile.brand_metrics?.gross_margin_rate,
                    shippingCost:
                        dataBrandProfile.brand_metrics?.shipping_cost_per_order,
                },
                brandPDescribe:
                    dataBrandProfile.brand_input?.brand_personality ?? '',
                brandPStart:
                    dataBrandProfile.brand_input?.brand_start_reason ?? '',
                customersWho: {
                    Describe:
                        dataBrandProfile.brand_input
                            ?.brand_customer_description ?? '',
                    Gender:
                        dataBrandProfile.brand_input?.brand_customers_gender
                            ?.split(', ')
                            .map((gender) => gender.trim()) ?? [],
                    AgeGroup:
                        dataBrandProfile.brand_input?.brand_customers_age
                            ?.split(', ')
                            .map((age) => age.trim()) ?? [],
                    Family:
                        dataBrandProfile.brand_input?.brand_customers_status
                            ?.split(', ')
                            .map((status) => status.trim()) ?? [],
                },
                customerServed: formatToOptionTypeCountry(
                    dataBrandProfile?.brand_input?.brand_markets
                ),
                productToFeature: formatToOptionTypeProduct(
                    dataBrandProfile?.brand_preferred_products
                ),
            })
        } catch {}
    },

    setupAssetsTemplates: (
        dataAssetTemplates: Needle.BrandAssetAndTemplate
    ): void => {
        try {
            set({
                aAndTAssets:
                    dataAssetTemplates.assets &&
                    dataAssetTemplates.assets.length !== 0
                        ? dataAssetTemplates.assets
                        : [{ platform: '', url: '' }],
                aAndTEmail: {
                    FromScratch:
                        dataAssetTemplates.config?.start_email_from_scratch ??
                        false,
                    Figma:
                        dataAssetTemplates.email_templates?.find(
                            (option) => option.platform === 'Figma'
                        )?.url ?? '',
                    Canva:
                        dataAssetTemplates.email_templates?.find(
                            (option) => option.platform === 'Canva'
                        )?.url ?? '',
                    Klaviyo:
                        dataAssetTemplates.email_templates?.find(
                            (option) => option.platform === 'Klaviyo'
                        )?.url ?? null,
                },
                aAndTBasicAssets: {
                    Name: dataAssetTemplates.brand_name ?? '',
                    Guide: dataAssetTemplates.basic_assets?.guidelines ?? [],
                    Logo: dataAssetTemplates.basic_assets?.logo ?? [],
                    Font: dataAssetTemplates.basic_assets?.fonts ?? [],
                    no_guidelines:
                        dataAssetTemplates.basic_assets?.no_guidelines ?? false,
                    no_fonts:
                        dataAssetTemplates.basic_assets?.no_fonts ?? false,
                },
            })
        } catch {}
    },

    setValueAssets: (aAndTAssets: EIAssets[]) =>
        set({
            aAndTAssets: aAndTAssets,
        }),

    setTemplateEmail: async (key: keyof EITemplatesEmail, data: any) => {
        const newState = { ...get().aAndTEmail, [key]: data }
        const dataReturn = await sendUpdateEmailTemplates(newState)
        if (dataReturn) {
            set({ aAndTEmail: newState })
        }
        return dataReturn
    },

    setValue: async (key: keyof BrandStoreProps, data: any) => {
        let newState = { ...get(), [key]: data }
        let dataReturn = undefined
        switch (key) {
            case 'brandMetrics':
                dataReturn = await updateBrandProfile({
                    brand_metrics: {
                        gross_margin_rate: newState.brandMetrics.grossMargin,
                        shipping_cost_per_order:
                            newState.brandMetrics.shippingCost,
                        marketing_spend: newState.brandMetrics.marketSpend,
                    },
                })
                break
            case 'brandPStart':
                dataReturn = await updateBrandProfile({
                    brand_input: {
                        brand_start_reason: newState.brandPStart,
                    },
                })
                break
            case 'brandPTell':
                dataReturn = await updateBrandProfile({
                    brand_input: {
                        brand_key_messages: newState.brandPTell,
                    },
                })
                break
            case 'brandPDescribe':
                dataReturn = await updateBrandProfile({
                    brand_input: {
                        brand_personality: newState.brandPDescribe,
                    },
                })
                break
            case 'customersWho':
                dataReturn = await updateBrandProfile({
                    brand_input: {
                        brand_customers_age:
                            newState.customersWho.AgeGroup.join(', '),
                        brand_customers_gender:
                            newState.customersWho.Gender.join(', '),
                        brand_customers_status:
                            newState.customersWho.Family.join(', '),
                        brand_customer_description:
                            newState.customersWho.Describe,
                    },
                })
                break
            case 'customerServed':
                dataReturn = await updateBrandProfile({
                    brand_input: {
                        brand_markets: formatToCountryFromOption(
                            newState.customerServed
                        ),
                    },
                })
                break
            case 'productToFeature':
                dataReturn = await updateBrandProfile({
                    brand_preferred_products: newState.productToFeature,
                })
                break
            case 'aAndTBasicAssets':
                dataReturn = await updateAssetsAndTemplates({
                    brand_name: newState.aAndTBasicAssets.Name,
                })
                break
        }
        if (dataReturn) {
            set(newState)
        }
        return dataReturn
    },
}))

brandStore.subscribe((state) => {
    state.productToCount = state.productToFeature.length > 0 ? 1 : 0

    let count = 0
    if (state.brandPStart) count += 1
    if (state.brandPTell.length > 0 && state.brandPTell[0]) count += 1
    if (state.brandPDescribe) count += 1
    state.brandPCount = count

    count = 0
    if (state.brandMetrics.marketSpend) count += 1
    state.brandMCount = count

    count = 0
    if (state.brandPStart) count += 1
    if (state.brandPTell.length > 0 && state.brandPTell[0]) count += 1
    if (state.brandPDescribe) count += 1
    state.brandPCount = count

    count = 0
    if (state.customerServed.length > 0 && state.customerServed[0]) count += 1
    if (state.customersWho.Describe) count += 1
    state.customerCount = count
})

export default brandStore

export const formatToOptionTypeCountry = (
    dataCountry: string[] | undefined
): OptionType[] => {
    const formatCountry: OptionType[] = []
    if (dataCountry) {
        dataCountry.forEach((country: string) => {
            const findCountry = MARKET_OPTIONS.find(
                (data) => data.label === country
            )
            if (findCountry) {
                formatCountry.push(findCountry)
            }
        })
    }
    return formatCountry
}

export const formatToOptionTypeProduct = (dataProduct: any): OptionType[] => {
    const formatProduct: OptionType[] = []
    if (Array.isArray(dataProduct)) {
        dataProduct?.forEach((product: any) => {
            formatProduct.push({
                value: product.id,
                label: product.title ?? '',
                image: product.image ?? '',
            })
        })
    }
    return formatProduct
}
export const formatToProductFromOption = (dataOption: OptionType[]): any[] => {
    const formatProduct: any[] = []
    dataOption.forEach((product: OptionType) => {
        formatProduct.push({
            id: product.value,
            title: product.label,
            image: product.image,
        })
    })
    return formatProduct
}

export const formatToCountryFromOption = (
    dataOption: OptionType[]
): string[] => {
    const listCountry: string[] = []
    dataOption.forEach((country: OptionType) => {
        listCountry.push(country?.label ?? country?.value ?? '')
    })
    return listCountry
}

const sendUpdateEmailTemplates = async (newState: EITemplatesEmail) => {
    const newTemplateEmail = []
    if (newState.Klaviyo !== null) {
        newTemplateEmail.push({
            platform: 'Klaviyo',
            url: '',
        })
    }
    if (newState.Canva !== '') {
        newTemplateEmail.push({
            platform: 'Canva',
            url: newState.Canva,
        })
    }
    if (newState.Figma !== '') {
        newTemplateEmail.push({
            platform: 'Figma',
            url: newState.Figma,
        })
    }
    return await updateAssetsAndTemplates({
        email_templates: newTemplateEmail,
        config: { start_email_from_scratch: newState.FromScratch },
    })
}
