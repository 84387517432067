import { components } from 'react-select'

export const handleDropUploadMultiple = (
    currentImage: File[],
    event: any
): File[] => {
    event.preventDefault()
    event.stopPropagation()
    const files = [...event.dataTransfer.files]
    const images = files.filter((file) => file.type.startsWith('image/'))
    const newListImage: File[] = [...currentImage]
    for (let i = 0; i < images.length; i++) {
        newListImage.push(images[i])
    }
    return newListImage
}

export const handleDragOver = (event: any): void => {
    event.preventDefault()
    event.stopPropagation()
}

export const handleUploadMultiple = (
    currentImage: File[],
    event: any
): File[] => {
    if (event.target.files && event.target.files.length > 0) {
        const newListImage: File[] = [...currentImage]
        event.target.files.forEach((imageFile: any) => {
            newListImage.push(imageFile)
        })
        event.target.value = null
        return newListImage
    } else return []
}

export const CustomOptionAddIdea = (props: any): JSX.Element => {
    return (
        <components.Option
            className="!bg-white !text-inherit hover:!bg-blue-100"
            {...props}
        >
            <div className=" flex flex-row items-center justify-between ">
                <div className="flex flex-col justify-center font-body-text">
                    {props.data.name}
                </div>
                <div className="bg-beige-2 px-3 py-6px rounded-full font-not-define !text-dark-blue">
                    {props.data.credits} Credit
                    {props.data.credits === 1 ? '' : 's'}
                </div>
            </div>
        </components.Option>
    )
}

export const ValueAddIdea = (props: any): JSX.Element => {
    return (
        <components.SingleValue {...props}>
            <div className="flex flex-row items-center">
                <div className="flex flex-col justify-center font-body-text">
                    {props.data.name}
                </div>
                <div className="bg-beige-2 px-3 py-6px rounded-full font-not-define ml-2">
                    {props.data.credits} Credit
                    {props.data.credits === 1 ? '' : 's'}
                </div>
            </div>
        </components.SingleValue>
    )
}
