import React, { useEffect, useRef, useState } from 'react'
import { EIBasicAssets } from '../../views/BrandProfilePage/type'
import { uploadClick } from './component'
import './TextSection.scss'
import UploadFile from './UploadFile'

interface CardTypeBrandAssetsProps {
    setCurrentData: (value: React.SetStateAction<any>) => void
    currentData: EIBasicAssets
    setIsDisable: React.Dispatch<React.SetStateAction<boolean>>
}

function CardTypeBrandAssets({
    setCurrentData,
    currentData,
    setIsDisable,
}: CardTypeBrandAssetsProps) {
    const [name, setName] = useState<string>('')
    const [logo, setLogo] = useState<Needle.metaDataAssets[] | null>(null)
    const [guide, setGuide] = useState<Needle.metaDataAssets[] | null>(null)
    const [fonts, setFonts] = useState<Needle.metaDataAssets[]>([])
    const [noGuide, setNoGuide] = useState<boolean>(false)
    const [noFont, setNoFont] = useState<boolean>(false)
    const [newLogo, setNewLogo] = useState<File[] | null>(null)
    const [newGuide, setNewGuide] = useState<File[] | null>(null)
    const [newFonts, setNewFonts] = useState<File[]>([])
    const [hasFontsModified, setHasFontsModified] = useState<boolean>(false)
    const fileLogoRef = useRef<HTMLInputElement>(null)
    const fileGuideRef = useRef<HTMLInputElement>(null)
    const fileFontRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (currentData) {
            setName(currentData.Name || '')
            setLogo(
                currentData.Logo && currentData.Logo.length > 0
                    ? currentData.Logo
                    : null
            )
            setGuide(
                currentData.Guide && currentData.Guide.length > 0
                    ? currentData.Guide
                    : null
            )
            setFonts(currentData.Font || [])
            setNoGuide(currentData.no_guidelines || false)
            setNoFont(currentData.no_fonts || false)

            setNewLogo(null)
            setNewGuide(null)
            setNewFonts([])
        }
    }, [])

    const updateData = () => {
        const isNameValid = name.trim() !== ''
        const isLogoValid =
            (newLogo === null && logo !== null) ||
            (newLogo && newLogo.length > 0)
        const isGuideValid = noGuide
            ? true
            : newGuide || (guide && guide.length > 0)
        const isFontValid = noFont
            ? true
            : newFonts.length > 0 || (fonts && fonts.length > 0)

        if (isNameValid && isLogoValid && isGuideValid && isFontValid) {
            const basicAssetsNewData = {
                Name: name,
                Logo: newLogo || (logo && logo.length > 0 ? logo : null),
                Guide: newGuide || (noGuide ? null : guide),
                Font: hasFontsModified
                    ? newFonts
                    : newFonts.length > 0
                      ? newFonts
                      : fonts,
                noGuide,
                noFont,
            }

            setCurrentData(basicAssetsNewData)
            setIsDisable(false)
        } else {
            setIsDisable(true)
        }
    }

    useEffect(() => {
        updateData()
    }, [name, logo, guide, fonts, newLogo, newGuide, newFonts, noGuide, noFont])

    const handleLogoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(event.target.files || [])
        setNewLogo(files.length > 0 ? [files[0]] : null)
    }

    const handleGuideUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(event.target.files || [])
        if (files.length > 0) {
            setNewGuide(files)
            setNoGuide(false)
        }
    }

    const handleFontUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(event.target.files || [])
        setNewFonts((prevFonts) => [...prevFonts, ...files])
    }

    const getLogoSrc = () => {
        if (newLogo && newLogo.length > 0) {
            return URL.createObjectURL(newLogo[0])
        } else if (logo && logo.length > 0 && logo[0]?.url) {
            return logo[0].url
        }
        return undefined
    }

    const handleGuideRemove = () => {
        setNewGuide(null)
        setGuide(null)
        setNoGuide(false) // Reset noGuide when guide is removed
    }

    return (
        <div className="mx-6 mt-6 mb-2 font-subtitle">
            <div>
                Brand name<span className="text-red-theme">*</span>
            </div>
            <textarea
                rows={1}
                id="brand_name"
                className="mt-1 border border-beige-outline font-body-text
                    py-3 px-4 w-full rounded-lg focus:outline-dark-blue mb-6"
                placeholder={'Sets the brand name'}
                value={name}
                onChange={(e) => {
                    setName(e.target.value)
                }}
            />
            <UploadFile
                typeOfFile={'JPG, PNG, GIF'}
                acceptsType={['image/jpeg', 'image/png', 'image/gif']}
                name={'Logo'}
                handleUpload={() => uploadClick(fileLogoRef)}
                data={newLogo || logo}
                setData={setNewLogo}
                isMust={true}
                imageData={
                    <img
                        alt="logo-brand"
                        className="w-12 aspect-square border border-beige-outline rounded-md"
                        src={getLogoSrc()}
                    />
                }
            />
            <div className="mb-6">
                <div>
                    Brand guide
                    <span className="text-red-theme">*</span>
                </div>
                {!guide && !newGuide && (
                    <div
                        className="flex flex-row items-center cursor-pointer mt-2"
                        onClick={() => setNoGuide(!noGuide)}
                    >
                        <div
                            className={`h-6 w-6 rounded border border-beige-outline ${noGuide ? 'bg-black' : ''} flex items-center justify-center`}
                        >
                            {noGuide && (
                                <img
                                    src="/assets/icons/checked-icon.svg"
                                    alt="checked-icon"
                                    className="w-3.5 h-3.5"
                                />
                            )}
                        </div>
                        <div className="pl-2 font-normal">
                            <p>I don't have a brand guide</p>
                        </div>
                    </div>
                )}

                {!noGuide && (
                    <UploadFile
                        typeOfFile={'PDF'}
                        acceptsType={['application/pdf']}
                        handleUpload={() => uploadClick(fileGuideRef)}
                        data={newGuide || guide}
                        setData={handleGuideRemove}
                        isMust={true}
                        imageData={
                            <img
                                alt="logo-brand"
                                className="w-12 aspect-square"
                                src="/assets/icons/brand-guide-icon.svg"
                            />
                        }
                    />
                )}
            </div>

            <div className="mb-6">
                <div>
                    Custom fonts
                    <span className="text-red-theme">*</span>
                </div>
                {((fonts.length === 0 && newFonts.length === 0) ||
                    (hasFontsModified && newFonts.length === 0)) && (
                    <div
                        className="flex flex-row items-center cursor-pointer mt-2"
                        onClick={() => {
                            setNoFont(!noFont)
                        }}
                    >
                        <div
                            className={`h-6 w-6 rounded border border-beige-outline ${noFont && 'bg-black'} flex items-center justify-center`}
                        >
                            <img
                                src="/assets/icons/checked-icon.svg"
                                alt="checked-icon"
                                className="w-3.5 h-3.5"
                            />
                        </div>
                        <div className="pl-2 font-normal">
                            <p>I don't have custom fonts</p>
                        </div>
                    </div>
                )}
                {!noFont && (
                    <UploadFile
                        typeOfFile={'TTF, OTF'}
                        acceptsType={['font/ttf', 'font/otf', 'ttf', 'otf']}
                        handleUpload={() => uploadClick(fileFontRef)}
                        data={
                            hasFontsModified
                                ? newFonts
                                : newFonts.length > 0
                                  ? newFonts
                                  : fonts
                        }
                        setData={(state) => {
                            setNewFonts(state)
                            setHasFontsModified(true)
                        }}
                        isMust={true}
                        imageData={
                            <img
                                alt="logo-brand"
                                className="w-12 aspect-square"
                                src="/assets/icons/font-image.svg"
                            />
                        }
                        multiple={true}
                    />
                )}
            </div>

            <input
                type="file"
                name="myImage"
                ref={fileLogoRef}
                onChange={handleLogoUpload}
                style={{ display: 'none' }}
                accept=".jpg, .png, .gif"
            />
            <input
                type="file"
                name="myImage"
                ref={fileGuideRef}
                onChange={handleGuideUpload}
                style={{ display: 'none' }}
                accept=".pdf"
            />
            <input
                type="file"
                name="myImage"
                ref={fileFontRef}
                onChange={handleFontUpload}
                style={{ display: 'none' }}
                accept=".ttf, .otf"
                multiple
            />
        </div>
    )
}

export default CardTypeBrandAssets
